*, *::before, *::after {
    box-sizing: border-box;
}

.landing-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.vi-header {
    height: 60px;
    width: 100%;
    background-color: var(--color-header-background);
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 1em;
}

.vi-footer {
    width: 100%;
    height: 60px;
    background-color: var(--color-accent);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    visibility: hidden;
}

.vi-footer::before,
.vi-footer::after {
    content: '';
    display: block;
}

.insight-viewer {
    flex-grow: 1;
}

.blue1-text {
    color: var(--color-accent);
}

.footer-text {
    font-family: Averta, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
}

.get-vi-app-button {
    width: 110px;
    height: 40px;
    border-radius: 3px;
    box-shadow: 0 8px 14px 0 rgba(0 0 0 / 25%);
    background-color: var(--color-white-1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-app-text {
    color: var(--color-white-1);
}

.app-teaser-background {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(86, 95, 114, 0.72);
}

.field-image-mock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 270px);
}

.field-image-mock img {
    max-width: 90%;
}

.insight-detail-data-rows.insight-detail-data-rows--speed_mismatch .insight-details-item-static-info {
    flex: 0 0 220px;
}

.insight-detail-data-rows.insight-detail-data-rows--pressure_drop .insight-details-item-static-info {
    flex: 0 0 16ch;
}

.image-loader > img,
.toggle-tags-icon,
.share-icon {
    filter: hue-rotate(260deg);
}
