body {
  margin: 0;
  height: 100%;
  font-family: Averta;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Averta,
    monospace;
}

#root {
  height: 100%;
}

:root {
  height: 100%;
  --color-white-1: #FFFFFF;
  --color-accent: #01a054;
  --color-header-background: #00210E;
}
