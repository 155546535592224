.app-teaser-container {
    height: 310px;
    padding: 25px 35px 25px 35px;
    display: flex;
    flex-direction: column;
    z-index: 500;
    background-color: #ffffff;
    bottom: 0;
    -webkit-backface-visibility: hidden;
    position: fixed;
}

.app-teaser-headline {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
}

.app-teaser-separator {
    width: 100%;
    height: 0;
    opacity: 0.5;
    border: solid 1px #d5dbe6;
    margin-top: 17px;
    margin-bottom: 17px;
}

.app-benefit-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 35px;
}

.app-benefit-options {
    display: flex;
}

.app-benefit-text {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    margin-left: 18px;
}

.app-teaser-continue {
    margin-top: 21px;
    font-size: 16px;
    line-height: 1.25;
    text-align: right;
    color: #5f6777;
}

.download-app-button-container {
    width: 100%;
    height: 50px;
    border-radius: 3px;
    background-color: var(--color-accent);
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-app-button-text {
    font-size: 16px;
    color: #ffffff;
}

